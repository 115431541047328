import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import Support from './assets/support.png';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
      <App />
      <footer>
        <a href="https://www.instagram.com/vitesse_digital1/" target='_blank' rel="noreferrer">
          <img src={Support} alt="Contact Support" className="footer" />
        </a>
      </footer>
  </React.StrictMode>
);
