import React from 'react';
import Logo from "./assets/logo512.png";
import './Navbar.css';

function Navbar() {
  return (
    <div className="navbar app-navbar">
      <a href="/">
        <img src={Logo} alt="Logo" />
      </a>
    </div>
  );
}

export default Navbar;