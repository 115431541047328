import React, { useState, useEffect } from "react";
import './App.css';
import emailjs from 'emailjs-com';
import Navbar from "./Navbar";
import QrCode from './assets/qr-code.jpg';
import { Alert } from "bootstrap";

const Data = [
  {
    category: "followers",
    translation: "粉丝",
    quantity: "1k",
    price: "60 HK$"
  },
  {
    category: "followers",
    translation: "粉丝",
    quantity: "3k",
    price: "160 HK$"
  },
  {
    category: "followers",
    translation: "粉丝",
    quantity: "5k",
    price: "215 HK$"
  },
  {
    category: "followers",
    translation: "粉丝",
    quantity: "10k",
    price: "375 HK$"
  },
  {
    category: "followers",
    translation: "粉丝",
    quantity: "20k",
    price: "640 HK$"
  },
  {
    category: "followers",
    translation: "粉丝",
    quantity: "50k",
    price: "1600 HK$"
  },
  {
    category: "followers",
    translation: "粉丝",
    quantity: "100k",
    price: "3100 HK$"
  },
  {
    category: "likes",
    translation: "点赞",
    quantity: "1k",
    price: "7 HK$"
  },
  {
    category: "likes",
    translation: "点赞",
    quantity: "5k",
    price: "30 HK$"
  },
  {
    category: "likes",
    translation: "点赞",
    quantity: "10k",
    price: "55 HK$"
  },
  {
    category: "likes",
    translation: "点赞",
    quantity: "50k",
    price: "175 HK$"
  },
  {
    category: "likes",
    translation: "点赞",
    quantity: "100k",
    price: "330 HK$"
  },
  {
    category: "views",
    translation: "浏览",
    quantity: "1k",
    price: "12 HK$"
  },
  {
    category: "views",
    translation: "浏览",
    quantity: "5k",
    price: "50 HK$"
  },
  {
    category: "views",
    translation: "浏览",
    quantity: "10k",
    price: "85 HK$"
  },
  {
    category: "views",
    translation: "浏览",
    quantity: "50k",
    price: "370 HK$"
  },
  {
    category: "views",
    translation: "浏览",
    quantity: "100k",
    price: "650 HK$"
  }
]

function App() {
  const [selectedCategory, setSelectedCategory] = useState('followers');
  const [selectedQuantity, setSelectedQuantity] = useState(null);
  const [link, setLink] = useState("");
  const [success, setSuccess] = useState(false);
  const [storedPrice, setStoredPrice] = useState("");
  const uniqueCategories = [...new Set(Data.map((item) => item.category))];

  const handleCategoryClick = (category) => {
    setSelectedCategory(category);
    setSelectedQuantity(null);
  };

  const handleQuantityClick = (quantity) => {
    setSelectedQuantity(quantity);
  };

  const handleLinkChange = (event) => {
    setLink(event.target.value);
    console.log(event.target.value);
  };

  const handleGoToPayment = () => {
    window.scrollTo(0, 0)
    if (selectedCategory && selectedQuantity && link) {
      const selectedData = Data.find(
        (item) => item.category === selectedCategory && item.quantity === selectedQuantity
      );

      const storedData = {
        category: selectedCategory,
        quantity: selectedQuantity,
        link: link,
        price: selectedData.price,
      };

      localStorage.setItem("selectedData", JSON.stringify(storedData));

      setStoredPrice(selectedData.price); // Store the price in state
      setSuccess(true); // Set success to true to show the success message and hide the form
    } else {
      alert("Please make sure to insert a link, and choose a category.");
    }
  };

  const handleFinish = () => {
    const storedData = JSON.parse(localStorage.getItem("selectedData"));

    if (storedData) {
      const emailParams = {
        category: storedData.category,
        quantity: storedData.quantity,
        link: storedData.link,
        price: storedData.price,
      };

      emailjs.send(
        'service_qqbqhqe', // Replace with your EmailJS service ID
        'template_eq1tho4', // Replace with your EmailJS template ID
        emailParams,
        'QFfuL8JMbsHf6jRvN' // Replace with your EmailJS user ID
      ).then((response) => {
        const sent_alert= "The order has been sent to customer service. Once we confirm receipt of payment, you will receive the order as soon as possible🤝"
        const alert_translate = "订单已发送至客服，我们确认收到付款后，您将尽快收到订单🤝"
        console.log('Order sent!', response.status, response.text);
        alert(`${alert_translate}. ${sent_alert}`);
      }).catch((error) => {
        console.error('Failed to send order:', error);
        alert('Failed to send order.');
      });
    } else {
      alert('No data found!');
    }
  };
  
  const GoToPayment = link && selectedCategory;

  return (
    <>
      {!success && <Navbar />}
      <div className="box-container">
        {success ? (
          <div className="success-message">
            <h2 className="alipay-code-msg">
              Scan this Alipay QR code to pay the {storedPrice} fees - 支付宝扫码付款{storedPrice}.
            </h2>
            <br />
            <div className="image-container">
              <img src={QrCode} alt="QR CODE" height="300" width="300" />
              <br />
              <br />
            </div>
            <div className="payment-notes">
              <div style={{ display: 'flex', flexDirection: 'column', gap: '3rem' }} className="go-to-payment paid">
                  <button onClick={handleFinish}>PAID - 付完了</button>
                  <p style={{ color: 'blue' }}>
                    (支付宝HK / 支付宝大陆 都可以用)
                    (both Alipay HK and Alipay mainland can be used)
                  </p>
              </div>
              <small className="payment-notes">
                <div className="note" style={{ textAlign: 'center' }}>
                  ⚠️ Note : <br />
                </div>
                1. After you make the payment click “ PAID - 付完了“ <br />
                2. Remember to add the name of your account as a note when paying <br /> 
                3. If you have any problem or question contact us on instagram : vitesse_digital <br />

                <div className="note" style={{ textAlign: 'center' }}>
                  ⚠️ 注意：
                </div>
                1. 付看后点击”PAID - 付完了“ <br />
                2. 付款时记得添加账户名字 <br />
                3. 如果您有任何问题请通过ins 联系我们: vitesse_digital <br />
              </small>
            </div>
          </div>
        ) : (
          <>
            <h2 className="input-label">Service type (服务类型):</h2>
            <div className="category-list">
              {uniqueCategories.map((category) => {
                const translation = Data.find(item => item.category === category).translation;
                return (
                  <label
                    key={category}
                    onClick={() => handleCategoryClick(category)}
                    className={`category-button ${selectedCategory === category ? 'active' : ''}`}
                  >
                    {category}<br />
                    {translation}
                  </label>
                );
              })}
            </div>
            <br />
            <h2 className="input-label">
              {selectedCategory === "followers" ? "Instagram Username (账户名字)" : selectedCategory === "likes" ? "Post Link (作品链接)" : "Video Link (视频链接)"}
            </h2>
            <input
              type="url"
              name="instagram-link"
              placeholder={selectedCategory === "followers" ? "Instagram Username (账户名字)" : selectedCategory === "likes" ? "Post Link (作品链接)" : "Video Link (视频链接)"}
              className="link-input"
              value={link}
              onChange={handleLinkChange}
            />
            <div className="quantity-parent-element">
              {selectedCategory && (
                <div className="quantity-list">
                  <h2 className="input-label">Quantity (数量):</h2>
                  <div
                    className={`grid-container ${
                      Data.filter((item) => item.category === selectedCategory).length <= 2
                        ? 'fewer-items'
                        : ''
                    }`}
                  >
                    {Data.filter((item) => item.category === selectedCategory).map((item, index) => (
                      <label
                        key={`${item.quantity}-${index}`}
                        onClick={() => handleQuantityClick(item.quantity)}
                        className={`grid-item ${selectedQuantity === item.quantity ? 'active' : ''}`}
                      >
                        {item.quantity}
                      </label>
                    ))}
                  </div>
                </div>
              )}
            </div>
            
            <br />
              <>
                <div className="price-display">
                  <h2 className="input-label">Price (价格):</h2>
                  {selectedCategory && selectedQuantity ? (
                    <input
                      type="text"
                      name="price"
                      value={Data.find((item) => item.category === selectedCategory && item.quantity === selectedQuantity).price || "0"}
                      className="link-input price-input"
                      disabled
                    />
                  ) : (
                    <input
                      type="text"
                      name="price"
                      value={"0 HK$"}
                      className="link-input price-input"
                      disabled
                    />
                  )}
                  <br />
                </div>
                <div>
                  <div className={`go-to-payment`}>
                    <button 
                      className={`${GoToPayment ? "" : "disabled"}`} 
                      onClick={handleGoToPayment}
                      disabled={!GoToPayment} // Optionally disable the button
                    >
                      GO TO PAYMENT - 去支付
                    </button>
                  </div>
                </div>
              </>
          </>
        )}
      </div>
    </>
  );
}

export default App;
